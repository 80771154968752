<template>
  <v-row>
    <v-col sm="2" cols="12">
      <div class="d-flex flex-column content-responsible">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.status", {}) }}
        </p>
        <status :value="invite.status.id">
          <strong class="primary-text--text">
            {{ invite.status.descricao }}
          </strong>
        </status>
      </div>
    </v-col>

    <v-col sm="2" cols="12">
      <div class="d-flex flex-column content-responsible">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.datetime") }}
        </p>
        <p class="ma-0 primary-text--text">
          {{ invite.atualizado_em || defaultEmptyData }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Status from "@/modules/inviteds/views/components/Status";

export default {
  name: "InviteDetail",
  props: {
    invite: {
      type: Object,
      required: true
    }
  },
  components: {
    Status
  },
  data: () => ({
    defaultEmptyData: "Não registrado"
  })
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-responsible {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
