<template>
  <v-card
    class="pa-4 mt-5 card-content d-flex flex-column justify-space-between"
  >
    <filters-invites-uba
      :filters="filters"
      :fnSearch="search"
      :fnClearFilters="clearFilters"
      :fnApplyFilters="applyFilters"
    />

    <v-divider></v-divider>

    <div class="my-5 d-flex buttons-content">
      <div>
        <v-btn
          depressed
          class="button"
          color="primary"
          :to="{ name: 'new-invite-uba' }"
          v-if="userIsEstadual"
        >
          Realizar Convite ABR-UBA
        </v-btn>
      </div>

      <div>
        <export-data-uba
          v-if="userIsAbrapa || userIsEstadual"
          class="button"
          :fnExportCSV="handleExportCSV"
          :fnExportExcel="handleExportExcel"
        />
      </div>
    </div>

    <h2 class="mt-3 mb-1 text-responsivel">
      Unidades de Beneficiamento convidadas
    </h2>

    <div class="mt-2">
      <v-data-table
        :items="items"
        :headers="headers"
        :items-per-page="tableInfo.limit"
        :no-data-text="
          $t('text.nenhum_registro_encontrado_por_tipo', { tipo: 'convite' })
        "
        :no-results-text="
          $t('text.nenhum_registro_encontrado_por_tipo', { tipo: 'convite' })
        "
        :mobile-breakpoint="0"
        hide-default-footer
      >
        <template v-slot:[`item.status.id`]="{ item }">
          <status :value="item.status.id">
            {{ item.status.descricao }}
          </status>
        </template>

        <template v-slot:[`item.algodoeira.unidade_produtiva`]="{ item }">
          <link-detail-productive-unit-action
            :productiveUnit="item.algodoeira.unidade_produtiva"
          />
        </template>

        <template v-slot:[`item.id`]="{ item }">
          <actions-invites-uba :detail="item" />
        </template>
      </v-data-table>
    </div>
    <div>
      <v-row class="mt-8" align="center">
        <v-col :cols="12" md="3" align-self="center">
          <span class="label-footer">
            {{
              `${$t("labels.show_register", {
                totalPerPage: tableInfo.totalPerPage,
                record: $tc("labels.record", tableInfo.totalPerPage),
                totalItems: tableInfo.totalItems
              })}`
            }}
          </span>
        </v-col>
        <v-col :cols="12" md="6">
          <v-pagination
            v-model="tableInfo.page"
            :length="tableInfo.totalPages"
          ></v-pagination>
        </v-col>
        <v-col :cols="12" md="3">
          <v-select
            v-model="tableInfo.limit"
            :items="perPageOptions"
            outlined
            color="primary-color-1"
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import FiltersInvitesUBA from "@/modules/invite-uba/views/components/FiltersInvitesUBA";
import ActionsInvitesUBA from "@/modules/invite-uba/views/components/ActionsInvitesUBA";
import ExportDataUBA from "@/modules/invite-uba/views/components/ExportDataUBA";

import LinkDetailProductiveUnitAction from "@/modules/invite-uba/views/components/detail-production-unit/LinkDetailProductiveUnitAction";
import Status from "@/modules/inviteds/views/components/Status";

import TableMixin from "@/modules/core/mixins/tableMixin";
import DownloadMixin from "@/modules/core/mixins/downloadMixin";

import { Profile } from "@/modules/inviteds/enums/profile.enum";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "InvitesUBA",

  components: {
    FiltersInvitesUba: FiltersInvitesUBA,
    ExportDataUba: ExportDataUBA,
    Status,
    ActionsInvitesUba: ActionsInvitesUBA,
    LinkDetailProductiveUnitAction
  },

  mixins: [TableMixin, DownloadMixin],

  data: () => ({
    items: [],
    headers: [
      {
        text: "Safra",
        value: "safra_realizacao_convite",
        width: 100,
        class: ["fixed-cell"],
        cellClass: ["fixed-cell", "font-weight-bold"]
      },
      {
        text: "Nome Fantasia - UBA",
        value: "algodoeira.nome_fantasia",
        width: 200,
        class: ["fixed-cell", "fixed-cell-second"],
        cellClass: ["fixed-cell", "fixed-cell-second", "text-uppercase"]
      },
      {
        text: "Razão Social - UBA",
        value: "algodoeira.razao_social",
        cellClass: "text-uppercase",
        width: 200
      },
      {
        text: "CPF/CNPJ - UBA",
        value: "algodoeira.cpf_cnpj",
        width: 160
      },
      {
        text: "UF",
        value: "algodoeira.uf",
        cellClass: "text-uppercase",
        width: 80
      },
      {
        text: "Município",
        value: "algodoeira.municipio",
        cellClass: "text-uppercase",
        width: 120
      },
      {
        text: "Dig. Extensão + GS1",
        value: "algodoeira.codigo_gs1_digito_extensao",
        width: 160
      },
      {
        text: "Unidade Produtiva",
        value: "algodoeira.unidade_produtiva",
        align: "center",
        width: 200
      },
      {
        text: "Status ABR-UBA",
        value: "status.id",
        width: 140
      },
      {
        text: "Data da última atualização",
        value: "atualizado_em",
        width: 200
      },
      {
        text: "Responsável(eis) ABR-UBA",
        value: "convidados",
        cellClass: "text-uppercase",
        width: 200
      },
      {
        text: "Categoria",
        value: "algodoeira.categoria",
        align: "center",
        cellClass: "text-center",
        width: 110
      },
      {
        text: "Safra de Certificação",
        value: "algodoeira.safra_certificacao",
        align: "center",
        width: 170
      },
      {
        text: "",
        value: "id"
      }
    ],
    tableInfo: {
      page: 1,
      totalItems: 0,
      totalPerPage: 0,
      totalPages: 0,
      limit: 10
    },
    perPageOptions: [
      { value: 10, text: "10 itens/página" },
      { value: 25, text: "25 itens/página" },
      { value: 50, text: "50 itens/página" },
      { value: 100, text: "100 itens/página" }
    ]
  }),

  computed: {
    ...mapGetters({
      user: "inviteds/getUser"
    }),

    userIsAbrapa() {
      return Profile.ABRAPA.check(this.user?.profile?.id);
    },

    userIsEstadual() {
      return Profile.STATE.check(this.user?.profile?.id);
    }
  },

  watch: {
    "tableInfo.limit": function() {
      this.tableInfo.page = 1;
      this.getInvites();
    },

    "tableInfo.page": function() {
      this.getInvites();
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),

    ...mapActions("inviteUba", [
      "fetchInvites",
      "exportInvitedUbasCSV",
      "exportInvitedUbasExcel"
    ]),

    async getInvites() {
      try {
        const payload = this.getPayloadData();
        const response = await this.fetchInvites(payload);
        this.items = this.formatData(response.results);

        this.setTableInfo({
          totalItems: response.count,
          totalPages: response.total_pages,
          totalPerPage: response.per_page
        });
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    updateData() {
      this.getInvites();
    },

    setTableInfo(meta) {
      this.tableInfo = {
        ...this.tableInfo,
        ...meta
      };
    },

    async search() {
      this.tableInfo.page = 1;
      await this.getInvites();
    },

    formatData(items) {
      return items;
    },

    async handleExportCSV() {
      try {
        const payload = this.getPayloadData();
        const blob = await this.exportInvitedUbasCSV(payload);
        this.downloadFromMemory(
          blob,
          "Unidades de Beneficiamento de Algodão Convidadas.csv"
        );
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async handleExportExcel() {
      try {
        const payload = this.getPayloadData();
        const blob = await this.exportInvitedUbasExcel(payload);
        this.downloadFromMemory(
          blob,
          "Unidades de Beneficiamento de Algodão Convidadas.xlsx"
        );
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    getRowHeight(params) {
      return params.data.convidados.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.fixed-cell {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}

.fixed-cell-second {
  left: 101px;
}

.buttons-content {
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

@media screen and (max-width: 959px) {
  .text-responsivel {
    text-align: center;
  }
  .buttons-content {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;

    .button {
      width: 100% !important;
    }
  }
}
</style>
