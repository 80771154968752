<template>
  <v-row>
    <v-col sm="2" cols="6">
      <div class="d-flex flex-column content-responsive">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.fantasy_name") }}
        </p>
        <p class="ma-0 primary-text--text text-uppercase font-weight-bold">
          {{ productiveUnit.nome_fantasia || defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="3" cols="6">
      <div class="d-flex flex-column content-responsive">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.corporate_name") }}
        </p>
        <p class="ma-0 primary-text--text text-uppercase">
          {{ productiveUnit.razao_social || defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="3" cols="6">
      <div class="d-flex flex-column content-responsive">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.cpf_cnpj") }}
        </p>
        <p class="ma-0 primary-text--text">
          {{ productiveUnit.cpf_cnpj || defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="2" cols="6">
      <div class="d-flex flex-column content-responsive">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.city") }}
        </p>
        <p class="ma-0 primary-text--text text-uppercase">
          {{ productiveUnit.municipio || defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="2" cols="6">
      <div class="d-flex flex-column content-responsive">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.gs1_plus_digit") }}
        </p>
        <p class="ma-0 primary-text--text">
          {{ productiveUnit.codigo_gs1_digito_extensao || defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="2" cols="6">
      <div class="d-flex flex-column content-responsive content-responsive">
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.unity_productive") }}
        </p>
        <btn-link
          v-if="productiveUnit.unidade_produtiva"
          @click="handleOpenDetailProductiveUnit"
        >
          <span class="text-uppercase">{{
            productiveUnit.unidade_produtiva.nome_propriedade
          }}</span>
        </btn-link>
        <p v-else class="ma-0 primary-text--text">
          {{ defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="2" cols="6">
      <div
        class="d-flex flex-column content-responsive"
        style="text-align:center;"
      >
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.category") }}
        </p>
        <p class="ma-0 primary-text--text">
          {{ productiveUnit.categoria || defaultEmptyData }}
        </p>
      </div>
    </v-col>
    <v-col sm="2" cols="6">
      <div
        class="d-flex flex-column content-responsive"
        style="text-align:center;"
      >
        <p class="ma-0 secondary-text--text">
          {{ $t("labels.harvest_certification") }}
        </p>
        <p class="ma-0 primary-text--text">
          {{ productiveUnit.safra_certificacao || defaultEmptyData }}
        </p>
      </div>
    </v-col>

    <dialog-detail-production-unit ref="dialogDetailProductionUnit" />
  </v-row>
</template>

<script>
import BtnLink from "@/modules/core/views/components/BtnLink";
import DialogDetailProductionUnit from "@/modules/invite-uba/views/components/detail-production-unit/DialogDetailProductionUnit";

export default {
  name: "ProductiveUnitDetail",

  props: {
    productiveUnit: {
      type: Object,
      required: true
    }
  },

  components: {
    BtnLink,
    DialogDetailProductionUnit
  },

  data: () => ({
    defaultEmptyData: "Não registrado"
  }),

  methods: {
    handleOpenDetailProductiveUnit() {
      if (!this.$refs?.dialogDetailProductionUnit?.open) {
        throw new Error(
          "Failed to open dialog, missing $refs or function open"
        );
      }
      const { id, safra_id } = this.productiveUnit.unidade_produtiva;
      this.$refs.dialogDetailProductionUnit.open(id, safra_id);
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-responsive {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
</style>
