<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" md="2">
        <v-select
          v-model.lazy="filters.safra"
          item-text="nome"
          item-value="id"
          color="primary"
          dense
          outlined
          :items="harvests"
          :label="$t('labels.harvest')"
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-autocomplete
          dense
          @input="$event => (filters.nome_fantasia = $event)"
          :search-input.sync="autocomplete.models.fantasyName"
          :items="autocomplete.items.fantasyName"
          :loading="autocomplete.loadings.fantasyName"
          :clearable="true"
          @click:clear="() => (autocomplete.items.fantasyName = [])"
          placeholder="Busque pelo nome fantasia"
          no-data-text="Nenhuma unidade de beneficiamento encontrada"
          hide-no-data
          item-text="nome_fantasia"
          item-value="nome_fantasia"
          :label="$t('labels.fantasy_name_of', { type: 'UBA' })"
          no-filter
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete
          dense
          @input="$event => (filters.razao_social = $event)"
          :search-input.sync="autocomplete.models.socialReason"
          :items="autocomplete.items.socialReason"
          :loading="autocomplete.loadings.socialReason"
          :clearable="true"
          @click:clear="() => (autocomplete.items.socialReason = [])"
          placeholder="Busque pela razão social"
          no-data-text="Nenhuma unidade de beneficiamento encontrada"
          hide-no-data
          item-text="razao_social"
          item-value="razao_social"
          :label="$t('labels.corporate_name_of', { type: 'UBA' })"
          no-filter
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model.lazy="filters.personalidade_juridica"
          item-disabled="desabilitado"
          item-text="nome"
          item-value="id"
          color="primary"
          dense
          outlined
          :items="legalPersonalities"
          :label="$t('labels.physical_person_legal_person')"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model.lazy="filters.cpf_cnpj"
          dense
          outlined
          color="primary"
          :label="$t('labels.cpf_cnpj_of', { type: 'UBA' })"
          type="text"
          v-mask="masks.cpfOrCnpj"
        />
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          v-model.lazy="filters.uf"
          item-text="uf"
          item-value="id"
          color="primary"
          dense
          outlined
          :items="ufs"
          :label="$t('labels.uf')"
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-autocomplete
          dense
          @input="$event => (filters.municipio = $event)"
          :search-input.sync="autocomplete.models.county"
          :items="autocomplete.items.county"
          :loading="autocomplete.loadings.county"
          :clearable="true"
          @click:clear="() => (autocomplete.items.county = [])"
          placeholder="Busque pelo município"
          no-data-text="Nenhuma unidade de beneficiamento encontrada"
          hide-no-data
          item-text="municipio"
          item-value="municipio"
          :label="$t('labels.city')"
          no-filter
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete
          dense
          @input="$event => (filters.codigo_gs1_digito_extensao = $event)"
          :search-input.sync="autocomplete.models.codeGs1Digit"
          :items="autocomplete.items.codeGs1Digit"
          :loading="autocomplete.loadings.codeGs1Digit"
          :clearable="true"
          @click:clear="() => (autocomplete.items.codeGs1Digit = [])"
          placeholder="Busque pelo código Dig. Extensão + GS1"
          no-data-text="Nenhuma unidade de beneficiamento encontrada"
          hide-no-data
          item-text="codigo_gs1_digito_extensao"
          item-value="codigo_gs1_digito_extensao"
          :label="$t('labels.gs1_plus_digit')"
          no-filter
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete
          dense
          @input="$event => (filters.unidade_produtiva = $event)"
          :search-input.sync="autocomplete.models.productiveUnit"
          :items="autocomplete.items.productiveUnit"
          :loading="autocomplete.loadings.productiveUnit"
          :clearable="true"
          @click:clear="() => (autocomplete.items.productiveUnit = [])"
          placeholder="Busque pela unidade produtiva"
          no-data-text="Nenhuma unidade produtiva encontrada"
          hide-no-data
          item-text="nome_propriedade"
          item-value="nome_propriedade"
          :label="$t('labels.unity_productive')"
          no-filter
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model.lazy="filters.status"
          item-text="nome"
          item-disabled="desabilitado"
          item-value="id"
          color="primary"
          dense
          outlined
          :items="invitesStatus"
          :label="$t('labels.status', { certificationType: 'ABR-UBA' })"
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-autocomplete
          dense
          @input="$event => (filters.responsavel = $event)"
          :search-input.sync="autocomplete.models.reponsibleAbrUba"
          :items="autocomplete.items.reponsibleAbrUba"
          :loading="autocomplete.loadings.reponsibleAbrUba"
          @click:clear="() => (autocomplete.items.reponsibleAbrUba = [])"
          :clearable="true"
          placeholder="Busque por Nome, CPF ou E-mail"
          no-data-text="Nenhum responsável encontrado"
          hide-no-data
          item-text="nome"
          item-value="nome"
          :label="$t('labels.responsibles', { certificationType: 'ABR-UBA' })"
          no-filter
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          v-model.lazy="filters.categoria"
          item-text="nome"
          item-value="id"
          color="primary"
          dense
          outlined
          :items="categories"
          :label="$t('labels.category')"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model.lazy="filters.safra_certificacao"
          item-text="nome"
          item-value="id"
          color="primary"
          dense
          outlined
          :items="harverst_certifications"
          :label="$t('labels.harvest_certification')"
        />
      </v-col>

      <v-col cols="12" md="4" class="ml-md-auto">
        <div style="height: 60%;" class="d-flex buttons-content">
          <v-btn
            color="white"
            class="button"
            @click="clearFilters"
            depressed
            width="150"
            >{{ $t("buttons.clear") }}</v-btn
          >
          <v-btn
            color="primary"
            depressed
            width="150"
            class="button"
            @click="fnSearch"
            >{{ $t("buttons.search") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MasksMixin from "@/modules/core/mixins/masksMixin";

import { uf } from "@/modules/core/enums/uf.enum";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";
import { LegalPersonalities } from "@/modules/core/enums/legal-personalities.enum";

import { debounce, pickBy, identity } from "lodash";

import { mapActions } from "vuex";

const autocompleteInitialData = () => ({
  models: {
    fantasyName: undefined,
    socialReason: undefined,
    productiveUnit: undefined,
    reponsibleAbrUba: undefined,
    codeGs1Digit: undefined,
    county: undefined
  },
  items: {
    fantasyName: [],
    socialReason: [],
    productiveUnit: [],
    reponsibleAbrUba: [],
    codeGs1Digit: [],
    county: []
  },
  loadings: {
    fantasyName: false,
    socialReason: false,
    productiveUnit: false,
    reponsibleAbrUba: false,
    codeGs1Digit: false,
    county: false
  }
});

const autocompleteMap = {
  fantasyName: "nome_fantasia",
  socialReason: "razao_social",
  productiveUnit: "nome",
  reponsibleAbrUba: "buscar",
  codeGs1Digit: "codigo_gs1_digito_extensao",
  county: "municipio"
};

export default {
  name: "FiltersInvitesUBA",

  mixins: [MasksMixin],

  props: {
    filters: {
      type: Object,
      required: true
    },

    fnApplyFilters: {
      type: Function,
      required: true
    },

    fnClearFilters: {
      type: Function,
      required: true
    },

    fnSearch: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    ufs: [],
    harvests: [],
    categories: [],
    harverst_certifications: [],
    legalPersonalities: [],
    invitesStatus: [],

    autocomplete: autocompleteInitialData()
  }),

  created() {
    this.loadFiltersData();
  },

  watch: {
    "autocomplete.models.fantasyName": function(newVal) {
      if (newVal === this.filters.nome_fantasia) {
        return;
      }
      this.debounceAutocomplete(
        "fantasyName",
        newVal,
        this.fetchProcessingUnitsInvitedsWithoutGlobalLoading,
        true
      );
    },
    "autocomplete.models.socialReason": function(newVal) {
      if (newVal === this.filters.razao_social) {
        return;
      }
      this.debounceAutocomplete(
        "socialReason",
        newVal,
        this.fetchProcessingUnitsInvitedsWithoutGlobalLoading,
        true
      );
    },
    "autocomplete.models.county": function(newVal) {
      if (newVal === this.filters.municipio) {
        return;
      }
      this.debounceAutocomplete(
        "county",
        newVal,
        this.fetchProcessingUnitsInvitedsWithoutGlobalLoading,
        true,
        false,
        true
      );
    },
    "autocomplete.models.productiveUnit": function(newVal) {
      if (newVal === this.filters.unidade_produtiva) {
        return;
      }
      this.debounceAutocomplete(
        "productiveUnit",
        newVal,
        this.fetchProductiveUnitsWithoutGlobalLoading,
        false,
        true
      );
    },
    "autocomplete.models.reponsibleAbrUba": function(newVal) {
      if (newVal === this.filters.responsavel) {
        return;
      }
      this.debounceAutocomplete(
        "reponsibleAbrUba",
        newVal,
        this.fetchResponsiblesABRUBAWithoutGlobalLoading,
        true
      );
    },
    "autocomplete.models.codeGs1Digit": function(newVal) {
      if (newVal === this.filters.codigo_gs1_digito_extensao) {
        return;
      }
      this.debounceAutocomplete(
        "codeGs1Digit",
        newVal,
        this.fetchProcessingUnitsInvitedsWithoutGlobalLoading,
        true
      );
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("inviteUba", [
      "fetchFiltersInvitedsUBA",
      "fetchProcessingUnitsInvitedsWithoutGlobalLoading",
      "fetchProductiveUnitsWithoutGlobalLoading",
      "fetchResponsiblesABRUBAWithoutGlobalLoading"
    ]),

    async loadFiltersData() {
      const {
        ufs,
        safras,
        categorias,
        safras_certicacoes,
        personalidades_juridicas,
        status_convites
      } = await this.fetchFiltersInvitedsUBA();
      this.categories = [{ id: "", nome: "Todas" }, ...categorias];
      this.ufs = [{ id: "", uf: "Todas" }, ...ufs];
      this.harvests = [{ id: "", nome: "Todas" }, ...safras];
      this.harverst_certifications = [
        { id: "", nome: "Todas" },
        ...safras_certicacoes
      ];
      this.legalPersonalities = [
        { id: "", nome: "Todos" },
        ...personalidades_juridicas
      ];
      this.invitesStatus = [{ id: "", nome: "Todos" }, ...status_convites];

      this.applyHarvestFilter();
    },

    debounceAutocomplete: debounce(async function(
      inputName,
      inputValue,
      fnFetch,
      withHarvest = false,
      withProcessingUnits = false,
      withUf = false
    ) {
      if (!inputValue) {
        return;
      }
      await this.callFetchAutocomplete(
        inputName,
        inputValue,
        fnFetch,
        withHarvest,
        withProcessingUnits,
        withUf
      );
    },
    500),

    async callFetchAutocomplete(
      inputName,
      inputValue,
      fnFetch,
      withHarvest = false,
      withProcessingUnits = false,
      withUf = false
    ) {
      try {
        const safra_sai = withHarvest ? this.filters.safra : null;
        const possui_algodoeira = withProcessingUnits ? true : null;
        const uf = withUf ? this.filters.uf : null;
        const payload = {
          [autocompleteMap[inputName]]: inputValue,
          safra_sai,
          possui_algodoeira,
          uf,
          limit: 30
        };

        this.autocomplete.loadings[inputName] = true;

        const response = await fnFetch(payload);

        this.autocomplete.items[inputName] = response.results;
      } catch (error) {
        this.toggleSnackbar({
          text:
            (error?.response?.data?.detail || error?.data?.detail) ??
            this.$t("text.erro_interno"),
          type: "error"
        });
      } finally {
        this.autocomplete.loadings[inputName] = false;
      }
    },

    clearFilters() {
      this.fnClearFilters();
      this.clearAutocompleteData();
      this.applyHarvestFilter();
    },

    clearAutocompleteData() {
      this.autocomplete = autocompleteInitialData();
    },

    applyHarvestFilter() {
      const currentHarvest = this.harvests[this.harvests.length - 1];

      this.fnApplyFilters({
        safra: currentHarvest.id
      });

      this.fnSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons-content {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 16px;
}

@media screen and (max-width: 959px) {
  .buttons-content {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;

    .button {
      width: 100% !important;
    }
  }
}
</style>
